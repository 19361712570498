var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", "text-xs-center": "" } },
    [
      _c(
        "div",
        { staticClass: "grid text-xs-center" },
        [
          _c("unit-card", {
            staticClass: "v-card-profile",
            attrs: { unit: _vm.unit },
            scopedSlots: _vm._u([
              {
                key: "tabs",
                fn: function () {
                  return [
                    _c(
                      "v-tabs",
                      {
                        staticClass: "tabs",
                        attrs: { "show-arrows": "" },
                        model: {
                          value: _vm.active,
                          callback: function ($$v) {
                            _vm.active = $$v
                          },
                          expression: "active",
                        },
                      },
                      [
                        _c("v-tabs-slider"),
                        _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#basis-gegevens",
                              "active-class": "active",
                            },
                          },
                          [_vm._v("Gegevens")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { attrs: { id: "profile-form" } },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "basis-gegevens" } },
                    [
                      _c("information-tab", {
                        attrs: { unit: _vm.unit, edit: _vm.isAdmin },
                        on: { save: _vm.saveUnit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }