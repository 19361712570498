var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "ma-5", attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center",
                    },
                    [
                      _c("div", [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "secondary-text text-lg-left form-title",
                          },
                          [_vm._v("Units")]
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { "x-large": "", color: "blue-grey" },
                          on: {
                            click: function ($event) {
                              return _vm.donwloadUnits()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", left: "", dark: "" } },
                            [_vm._v("mdi-file-excel")]
                          ),
                          _vm._v(" Download Units "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$unitsList",
                              showFilters: true,
                              showSearchBar: true,
                            },
                            on: { filterData: _vm.setFilterOnList },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.unitsV2,
                            options: _vm.options,
                            "server-items-length": _vm.totalUnits,
                            loading: _vm.loading,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15],
                            },
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.options = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "body",
                              fn: function ({ items }) {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function (item) {
                                      return _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.brand["name"]))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.project["name"]))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.type))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-end px-0" },
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "justify-center",
                                                attrs: {
                                                  to: {
                                                    name: "unitDetail",
                                                    params: { id: item._id },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      " mdi-home-edit-outline "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.canEdit
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.confirmDeleteDialogItem =
                                                          item
                                                        _vm.confirmDeleteDialog = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-delete-outline "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn",
                                  },
                                },
                                [_vm._v(" Sorry, geen gebruikers gevonden ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row mt-10" },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "d-flex flex-column pr-8 ma-0" },
                            [
                              _vm.canEdit
                                ? _c("v-file-input", {
                                    staticClass: "file-input mb-3",
                                    attrs: {
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-paperclip",
                                      "prepend-icon": "",
                                      placeholder: "Units uploaden",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.file,
                                      callback: function ($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: { outlined: "", loading: _vm.loading },
                                  on: { click: _vm.uploadFile },
                                },
                                [_vm._v("Units opladen")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function ($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog",
              },
            },
            [
              _vm.confirmDeleteDialog
                ? _c("confirm-delete", {
                    attrs: { item: _vm.confirmDeleteDialogItem },
                    on: {
                      cancel: function ($event) {
                        _vm.confirmDeleteDialogItem = null
                        _vm.confirmDeleteDialog = false
                      },
                      delete: _vm.deleteUnit,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }