var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canEdit
        ? _c(
            "v-btn",
            {
              staticClass: "text-uppercase float-right white--text",
              attrs: { outlined: "", color: "grey", disabled: _vm.disableEdit },
              on: {
                click: function ($event) {
                  return _vm.addNextYear()
                },
              },
            },
            [_vm._v(" Volgende periode toevoegen ")]
          )
        : _vm._e(),
      _c("h4", { staticClass: "mb-9" }, [
        _vm._v("Prijzen van de afgelopen en huidige periodes"),
      ]),
      _c("v-data-table", {
        attrs: {
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          headers: _vm.headers,
          items: _vm.unit.prices,
          "item-key": "email",
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function ({ items }) {
              return [
                _c(
                  "tbody",
                  _vm._l(items, function (item) {
                    return _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm._f("formatDate")(item.startDate))),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm._f("formatDate")(item.endDate))),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(
                          _vm._s(item.deposit ? item.deposit.amount : "-")
                        ),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(item.rent ? item.rent.amount : "-")),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(item.rent ? item.rent.stayAmount : "-")),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(item.egwi ? item.egwi.amount : "-")),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(
                          _vm._s(item.inventory ? item.inventory.amount : "-")
                        ),
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(
                          _vm._s(item.studentTax ? item.studentTax.amount : "-")
                        ),
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-right" },
                        [
                          _vm.canEdit
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { disabled: _vm.disableEdit },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil-outline ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.canEdit
                        ? _c(
                            "td",
                            { staticClass: "justify-center" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "grey" },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.confirmDeleteDialogItem = {
                                        _id: { unit: _vm.unit, item },
                                      }),
                                        (_vm.confirmDeleteDialog = true)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-delete ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      }),
      _vm.dialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", scrollable: "", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("price-edit", {
                attrs: {
                  newStartDate: _vm.startDateForNewPrice,
                  recurringCompanies: _vm.recurringCompanies,
                  addPriceYear: _vm.addPriceYear,
                  subscriptionPlans: _vm.subscriptionPlans,
                  companies: _vm.companies,
                  editedItem: _vm.editedItem,
                },
                on: { save: _vm.save, close: _vm.close },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function ($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog",
              },
            },
            [
              _vm.confirmDeleteDialog
                ? _c("confirm-delete", {
                    attrs: { item: _vm.confirmDeleteDialogItem },
                    on: {
                      cancel: function ($event) {
                        _vm.confirmDeleteDialogItem = null
                        _vm.confirmDeleteDialog = false
                      },
                      delete: _vm.deleteYear,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }