var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.unit
    ? _c(
        "v-card",
        { staticClass: "profile" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { md4: "", xs12: "" } }, [
                _c("div", { staticClass: "profile_main" }, [
                  _c("div", { staticClass: "profile_main-info" }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "grey--text text-lg-left text--darken-3 mt-3",
                      },
                      [_vm._v(_vm._s(_vm.unit.project["name"]))]
                    ),
                    _c("h4", { staticClass: "grey--text text-lg-left" }, [
                      _vm._v(_vm._s(_vm.unit.type)),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-divider", {
                staticClass: "mx-3",
                attrs: { inset: "", vertical: "" },
              }),
              _c("v-flex", { attrs: { md4: "", xs12: "" } }, [
                _c("div", { staticClass: "profile_contact" }, [
                  _c(
                    "div",
                    { staticClass: "profile_contact-row mt-3" },
                    [
                      _c("v-icon", [_vm._v("mdi-folder-pound-outline")]),
                      _c("h3", { staticClass: "text-lg-left" }, [
                        _vm._v(" " + _vm._s(_vm.unit.name)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "profile_contact-row mt-3" },
                    [
                      _c("v-icon", [_vm._v("mdi-floor-plan")]),
                      _c("h3", { staticClass: "text-lg-left" }, [
                        _vm._v(" " + _vm._s(_vm.unit.type)),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("v-divider", {
                staticClass: "mx-3",
                attrs: { inset: "", vertical: "" },
              }),
              _c("v-flex", { attrs: { md3: "", xs12: "" } }, [
                _c("div", { staticClass: "profile_address" }, [
                  _c("div", { staticClass: "profile_address-row" }, [
                    _c("h4", { staticClass: "grey--text mt-3 text-lg-left" }, [
                      _vm._v("ADRES"),
                    ]),
                    _vm.unit.project.address
                      ? _c("div", [
                          _c("h4", { staticClass: "text-lg-left" }, [
                            _vm._v(_vm._s(_vm.unit.project.address["street"])),
                          ]),
                          _c("h4", { staticClass: "text-lg-left" }, [
                            _vm._v(
                              _vm._s(_vm.unit.project.address["zip"]) +
                                " " +
                                _vm._s(_vm.unit.project.address["city"])
                            ),
                          ]),
                        ])
                      : _c("div", [_c("h4", [_vm._v("Adres ontbreekt")])]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._t("tabs"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }