import { render, staticRenderFns } from "./user-edit.vue?vue&type=template&id=545cb90f&scoped=true"
import script from "./user-edit.vue?vue&type=script&lang=js"
export * from "./user-edit.vue?vue&type=script&lang=js"
import style0 from "./user-edit.vue?vue&type=style&index=0&id=545cb90f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545cb90f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('545cb90f')) {
      api.createRecord('545cb90f', component.options)
    } else {
      api.reload('545cb90f', component.options)
    }
    module.hot.accept("./user-edit.vue?vue&type=template&id=545cb90f&scoped=true", function () {
      api.rerender('545cb90f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/users/detail/user-edit.vue"
export default component.exports